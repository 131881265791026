<template>
	<div
		class="project"
		:class="{ 'project--blank': blank, 'active': activeClass }"
	>
		<!-- @mouseover="() => hover = true"
		@mouseleave="() => hover = false" -->
		
		<game-icon class="project__image" :image-link="project.icon_link || ''"/>
		<div class="project__info" v-if="blank">
			<div class="project__blank-text" v-if="blank">
				Нет активных проектов
			</div>
		</div>
		<div class="project__info" v-else>
			<div class="project__header">
				<router-link
					class="project__link-over"
					:to="{name: 'Game', params: {gameId: project.id}}"
				>
					
				</router-link>
				<span class="project__link">
					<span class="project__title">
						{{project.full_name || project.short_name}}
					</span>
				</span>
				<div class="project__pin">
					<b-pin
						:parent-hover="hover"
						gray
						:rotate="!project.pinned"
						:hidden="!project.pinned"
					/>
						<!-- @click="(e) => pinGame(e)" -->
				</div>
			</div>
			<div class="project__version">
				<span class="version-word">
					Версия:
				</span>
				<template v-if="version">
					{{version}}
				</template>
				<span
					v-else
					class="version__null"
				>
					нет данных
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/api"
import pin from "./basic/icons/pin"
import gameIcon from "./basic/project_icon"

export default {
	name: 'project-item',
	components: {
		"b-pin": pin,
		"game-icon": gameIcon
	},
	data: () => ({
		hover: false
	}),
	props: {
		blank: {
			type: Boolean,
			default: false
		},
		project: {
			type: Object,
			default: () => ({})
		},
		active: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		version(){
			if (!this.project?.latest_version?.updated) {
				return false
			}
			const date = new Date(this.project.latest_version.updated)
			let ye = date.getFullYear();
			ye = ye % 100;

			const strdate = [date.getDate(), date.getMonth() + 1, ye]
			
			return `${this.project.latest_version.version} (${strdate.map(el => String(el).padStart(2, "0")).join('.')})`
		},
		activeClass(){
			return this.$route.params.gameId == this.project.id
		}
	},
	methods: {
		pinGame(){
			api.manageProject(this.project.id, !this.project.pinned)
			.then( res => {
				if(res == 'OK'){
					this.$store.dispatch('projects/fetch')
				}
			})
		}
	}
}
</script>

<style scoped lang="sass">

.project
	display: flex
	align-items: stretch
	grid-gap: 16px
	padding: 15px
	background-color: $white
	// overflow: hidden
	position: relative
	// border: 1px solid $buttons-border
	box-sizing: border-box
	border-radius: 24px
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06), 0 0 0 1px $buttons-border
	cursor: pointer
	transition: box-shadow .2s ease

	// &__link::after
	// 	content: ''
	// 	position: absolute
	// 	top: 0
	// 	height: 0
	// 	right: 0
	// 	left: 0
	// 	height: 100%
	// 	border-radius: 24px

	&__link-over
		// background: rgba(0, 255, 0, .9)
		position: absolute
		z-index: 1
		top: 0
		height: 0
		right: 0
		left: 0
		height: 100%
		border-radius: 24px

	&:hover
		// border: 1px solid $accent-dark
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06), 0 0 0 1px $accent-dark

		.project__title
			color: $accent-buttons
	
	&__header
		display: flex
		grid-gap: 16px

	&__image
		flex-shrink: 0
		height: 72px
		width: 72px
		border-radius: 17px
		img
			display: block
		
		&.have-image
			img
				object-fit: contain
				height: 100%
				width: 100%
				box-shadow: inset 0 0 0 1.3px rgba(0,0,0, .06)

	&__info
		display: flex
		flex-direction: column
		justify-content: space-between
		padding: 4px 0
		min-width: 0
		flex: 1 0

	&__version
		font-size: 14px
		line-height: 17px
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis
		text-decoration: none

	&__title
		display: -webkit-box
		text-decoration: underline
		-webkit-line-clamp: 2
		-webkit-box-orient: vertical
		overflow: hidden
		text-overflow: ellipsis
		transition: color .2s ease
		// padding-right: 24px

	&__link
		flex-grow: 1
		color: $black

	&__pin
		// display: none
		// background: rgba(255, 0, 0, .9)
		min-width: 23px
		flex-shrink: 0
		// position: relative
		// $offset: 8px
		// left: $offset
		// bottom: $offset
		// z-index: 50
		// position: absolute
		// top: 18px
		// right: 18px
		// & > div
		// 	position: relative

.version
	&-word
		color: $third-dark
	&__null
		color: $error

.project--blank
	background-color: transparent
	box-shadow: none

	.project__info
		font-size: 20px
		line-height: 24px
		color: $secondary-dark

@media screen and (min-width: $sm)
	.project
		&__image
			width: 60px
			height: 60px
			border-radius: 15px
			
		&__info
			padding: 0


@media screen and (min-width: $md)
	.project
		&__image
			width: 72px
			height: 72px
			border-radius: 17px
			
		&__info
			padding: 3px
			line-height: 20px

	.project
		&__pin
			display: block
			// & > div
			// 	position: relative
			// 	left: 10px
			// 	bottom: 10px

</style>

<style lang="sass">

.active
	&.project
		color: $white
		background-color: $accent-buttons
		.project__title
			text-decoration: none
			color: $white
		.project__link
			text-decoration: none	
		&:hover
			.project__title
				color: $white

</style>