<template>
	<div
		v-if="options.visible"
		class="dropdown"
		:class="{
			'dropdown--top': top,
			'dropdown--left': left,
			'dropdown--bottom': bottom,
			'dropdown--right': right,
		}"
		:style="styles"
	>
		<div
			class="dropdown__body"
		>
			<slot></slot>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: "absolute",
	props: {
		'offsetX': {
			type: Number,
			default: 0
		},
		'offsetY': {
			type: Number,
			default: 0
		},
		'top': {
			type: Boolean,
		},
		'left': {
			type: Boolean,
		},
		'bottom': {
			type: Boolean,
		},
		'right': {
			type: Boolean,
		},
	},
	data: () => ({
		body: null
	}),
	methods: {
		closeDropdown(e){
			if(!this.options.visible)
				return
      if (!this.$el.contains(e.target)) {
				this.$store.commit('dropdown/setVisible', false)
      }
		}
	},
	computed: {
		...mapGetters('dropdown', ["options"]),
		styles(){
			const data = {
				left: `${this.options.x + this.offsetX}px`,
				top: `${this.options.y + this.offsetY}px`
			}
			return data
		}
	},
  mounted() {
    document.addEventListener('click', this.closeDropdown)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdown)
  }
}
</script>

<style scoped lang="sass">

.dropdown
	position: absolute
	top: 0
	left: 0
	width: 0
	height: 0
	z-index: 2
	// filter: drop-shadow(0 12px 20px rgb(0 0 0 / 16%))

	&__body
		display: block
		position: absolute
		transform: translate(-50%, -50%)

	&--top
		.dropdown__body
			transform: translate(-50%, -100%)

	&--left
		.dropdown__body
			transform: translate(-100%, -50%)
			
	&--bottom
		.dropdown__body
			transform: translate(-50%, 0%)

	&--right
		.dropdown__body
			transform: translate(0, -50%)

</style>