<template>
<transition name='fade'>
	<div v-if="state" class="tooltip">
		<div class="tooltip__text">
Используйте <heart-icon color="#000000"/> что-бы сделать 
добавить ветку в избранное и поднять
ее наверх.
		</div>
		<div class="tooltip__close" @click="() => state = false">
			Закрыть<span class="tooltip__word"> подсказку</span>
		</div>
	</div>
</transition>
</template>

<script>
import heartIcon from "@/components/basic/icons/heart.vue";

export default {
	name: 'tooltip',
	components: {
		'heart-icon': heartIcon,
	},
	data: () => ({
		state: true
	})
}
</script>

<style scoped lang="sass">
.tooltip
	border-radius: 12px
	background: $tooltip-background
	color: $main-tooltip
	padding: 12px 18px 14px 16px
	display: flex
	flex-direction: column
	grid-gap: 12px

	&__close
		text-decoration: underline
		font-size: 12px
		line-height: 19px
		cursor: pointer
		white-space: nowrap

	&__text
		font-size: 14px
		line-height: 19px
		flex-grow: 1

@media screen and (min-width: $sm)
	.tooltip
		flex-direction: row

		&__word
			display: none

@media screen and (min-width: $md)
	.tooltip
		&__word
			display: inline

.fade-enter-active, .fade-leave-active 
	transition: opacity .4s ease, padding .5s ease, margin-bottom .4s ease

.fade-enter, .fade-leave-to
	opacity: 0
	padding-top: 0px
	padding-bottom: 0px
	margin-bottom: 0px

</style>