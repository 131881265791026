<template>
	<div
		class="heart"
	>
		<svg
			class="heart__item"
			:class=" {
				'heart__item--unactive': unactive,
				'heart__item--color': color,
			} "
			width="18"
			height="15"
			viewBox="0 0 18 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
				<path
					v-if="filled"
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M9.67291 1.49602C11.6676 -0.498675 14.9065 -0.498675 16.9012 1.49602C18.896 3.49073 18.896 6.72973 16.9012 8.72443L16.3849 9.24072C16.1525 9.47312 15.9032 9.67842 15.641 9.85662L9.67291 14.9201L3.71071 9.86073C3.44631 9.68153 3.19501 9.47482 2.96081 9.24072L2.44451 8.72443C0.449811 6.72973 0.449811 3.49073 2.44451 1.49602C4.43921 -0.498675 7.67811 -0.498675 9.67291 1.49602Z"
					:fill="color"
				/>
				<path
					v-else
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M8.72443 1.49602C10.7191 -0.498675 13.958 -0.498675 15.9527 1.49602C17.9475 3.49073 17.9475 6.72973 15.9527 8.72443L15.4364 9.24072C15.204 9.47312 14.9547 9.67842 14.6925 9.85662L8.72443 14.9201L2.76222 9.86073C2.49782 9.68153 2.24653 9.47482 2.01233 9.24072L1.49602 8.72443C-0.498675 6.72973 -0.498675 3.49073 1.49602 1.49602C3.49073 -0.498675 6.72963 -0.498675 8.72443 1.49602ZM7.31023 2.91033L7.89603 3.49603L8.72383 4.33743L9.55282 3.49603L10.1386 2.91022C11.3528 1.69602 13.3243 1.69602 14.5385 2.91022C15.7527 4.12442 15.7527 6.09602 14.5385 7.31023L14.0222 7.82653C13.8804 7.96833 13.7283 8.09373 13.5683 8.20253L13.48 8.26252L8.72453 12.2972L3.97382 8.26573L3.88422 8.20502C3.72282 8.09562 3.56953 7.96943 3.42663 7.82653L2.91033 7.31023C1.69613 6.09602 1.69613 4.12442 2.91033 2.91022C4.12442 1.69602 6.09602 1.69613 7.31023 2.91033Z"
					:fill="color"
				/>
		</svg>
	</div>
</template>

<script>
export default {
	name: 'heart-icon',
	props: {
		filled: {
			type: Boolean,
			default: false
		},
		unactive: {
			type: Boolean,
			default: false
		},
		color: {
			type: [String, Boolean],
			default: false,
			validator(color){
				if(typeof color == "string" || (typeof color == "boolean" && !color)) return true
				return false
			}
		}
	}

}
</script>

<style scoped lang='sass'>
.heart
	$size: 18px
	height: $size
	width: $size
	display: inline-flex
	justify-content: center
	align-items: center

	&:hover
		.heart__item
			path
				fill: #FF3333

	&__item
		path
			transition: fill .2s ease
			fill: #FF3333
	
		&--unactive
			path
				fill: $third-dark

		&--color
			path
				fill: initial

</style>