<template>
	<div class="info">
		<div class="info__header">
			Дополнительная информация
		</div>
		<div class="info__container">
			<div class="info__item">
				<div class="info__title">
					Документация:
				</div>
				<a class="info__link" :href="notionLink">
					<div class="info__icon">
						<svg-icon icon-class="notion" />
					</div>
					<div class="info__value">
						Notion
					</div>
				</a>
			</div>
			<div class="info__item">
				<div class="info__title">
					Сайт:
				</div>
				<a class="info__link" :href="siteLink">
					<div class="info__icon">
						<svg-icon icon-class="main_site_logo" />
					</div>
					<div class="info__value">
						Beresnev games
					</div>
				</a>
			</div>
			<div class="info__item info__item--game-page">
				<div class="info__title">
					Страница игры:
				</div>
				<div class="button__container">
					<a class="button" :href="appstoreLink">
						<svg-icon icon-class="platform-apple-black"/> Apple iOS
					</a>
					<span class="info__title--hidden">
						Страница игры:
					</span>
					<a class="button" :href="googleplayLink">
						<svg-icon icon-class="google_play"/> Google play
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "project-info",
	props: {
		"notion-link": String,
		"appstore-link": String,
		"googleplay-link": String,
		"site-link": String
	},
}
</script>

<style scoped lang="sass">

.info
	&__header
		font-weight: 600
		font-size: 18px
		line-height: 28px
		margin-bottom: 16px

	&__container
		display: flex
		flex-direction: column
		grid-gap: 13px
		font-size: 14px

	&__title
		line-height: 20px
		font-feature-settings: 'ss04' on

		&--hidden
			display: none
			transform: translateY(3px)
			margin-right: -10px
			margin-left: 12px

	&__icon
		font-size: 18px

	&__item
		display: flex
		grid-gap: 10px

		&--game-page
			flex-direction: column

	&__link
		display: flex
		align-items: center
		grid-gap: 6px
		text-decoration: none
		color: $accent-buttons
		cursor: pointer

		&:hover
			text-decoration: underline

.button
	display: inline-block
	padding: 10px 23px
	border: 1px solid $black
	border-radius: 12px
	text-decoration: none
	font-size: 14px
	color: $black
	width: 156px
	text-align: center
	margin-bottom: 10px
	white-space: nowrap
	cursor: pointer

	&__container
		display: flex
		flex-wrap: wrap
		grid-gap: 0 15px

	svg
		font-size: 16px
		margin-right: 2px

@media screen and (min-width: $sm) 
	.info
		&__header
			font-size: 14px
			margin-bottom: 10px

		&__container
			flex-direction: row
			grid-gap: 38px
	
		&__icon
			font-size: 16px

		&__item
			flex-direction: row
			flex-wrap: wrap
			align-items: start
			align-content: flex-start
			grid-gap: 2px 10px
			font-size: 12px
			flex-direction: column

			&:nth-child(1)
				order: 1
			&:nth-child(2)
				order: 3
			&--game-page
				order: 2
	

	.button
		padding: 0
		width: auto
		border: none
		color: $accent-buttons
		font-size: 12px

		&__container
			flex-direction: column
			align-items: start

		
		&:hover
			text-decoration: underline

		svg
			font-size: 17px

@media screen and (min-width: $md)
	.button
		&__container
			flex-direction: row
			align-items: start
			
@media screen and (min-width: $lg)
	.info
		&__item
			flex-direction: row
		&__title
			&--hidden
				display: inline
		
		


</style>