<template>
	<div class="archive" ref="container">
		<div class="archive__header">
			<div class="archive__title">Архив <span v-if="!archive?.archived_count">пуст</span><span v-if="archive?.archived_count" class="archive__count">{{archive?.archived_count ?? 0}}</span></div>
			<div
				v-if="archivedBranches.length > 0"
				class="archive__btn"
				:class="{ 'archive__btn--active': full }"
				@click="toogleFull"
			>
				<div class="archive__cross-btn"></div>
			</div>
		</div>
		<div v-if="showBranches" class="archive__content" :class="{ 'archive__hidden': !full }">
			<b-branch-container
				ref="container"
				:branches="showBranches"
				:count="full ? undefined : 2"
				:no-fade="!full"
				:extension="extension"
				:project-id="projectId"
			/>
			<div
				v-if="!full"
				class="archive__fade"
				@click="toogleFull"
			></div>
		</div>
	</div>
</template>

<script>
import branchContainer from "./branch-container"
import { nextTick } from 'vue'

export default {
	name: 'archive',
	components: {
		"b-branch-container": branchContainer,
	},
	props: {
		branches: {
			type: Object,
			default: () => ({})
		},
		extension: {
			type: String,
			default: 'Все'
		},
		parent: {
			type: Object
		},
		"project-id": {
			type: Number,
			default: 0
		}
	},
	data: () => ({
		full: false,
		parrentWidth: 0,
		show: [],
		offset: 0,

		// editable
		count: 8,
	}),
	computed: {
		showBranches(){
			if(this.full)
				return this.archivedBranches.slice(0, this.offset)


			return this.archivedBranches.slice(0, Math.max(1, ~~this.parrentWidth / 368))
		},
		archive(){
			const data = this.$store.getters['projects/archived']?.[this.projectId]
			return data
		},
		loading(){
			return this.$store.getters['projects/isArchiveLoading']
		},
		archivedBranches(){
			return this.archive?.branches ?? []
		}
	},
	methods: {
		toogleFull(){
			this.full = !this.full
			this.$store.dispatch('projects/fetchArchived',  {project_id: this.projectId, unlimited: true});
			this.addToShow(this.count * 2)
		},
		setParrentWidth(){
			this.parrentWidth = this.$refs.container.offsetWidth

		},
		addToShow(count){
			this.offset += count ?? this.count
		}
	},
	mounted(){
		this.setParrentWidth()

		const update = debounce(() => {
			this.addToShow()
		}, 50)

		nextTick()
		.then(() => {
			this.parent.addEventListener('scroll', () => {
				const observerOffset = 500;
				let bottomOfWindow = this.parent.scrollTop + window.innerHeight > this.parent.scrollHeight - observerOffset;
	
				if (bottomOfWindow) {
					update();
				}
			})
		})
	},
	watch: {
		$windowWidth(){
			this.setParrentWidth()
		}
	}
}

function debounce(func, wait, immediate = false) {
	let timeout;

	return function executedFunction() {
		const context = this;
		const args = arguments;
		const later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	}
}
</script>

<style scoped lang="sass">

.archive
	&__header
		display: flex
		justify-content: space-between
		align-items: center
		margin-bottom: 24px
	
	&__title
		font-weight: 600
		font-size: 28px
		line-height: 36px
	
	&__count
		display: inline-block
		margin-left: 18px
		font-size: 28px
		line-height: 36px
		color: $third-dark
		font-weight: 400

	&__btn
		display: flex
		justify-content: center
		align-items: center
		width: 34px
		height: 34px

		background: $gray-light-2
		border-radius: 10px
		cursor: pointer
		transition: background-color .2s ease, box-shadow .2s ease

		&:hover
			background: darken($gray-light-2, 13%)
			box-shadow: 0px 4px 8px rgba($buttons-border, 0.32)

		&--active
			background-color: $black

			&:hover
				background: lighten($black, 30%)
				box-shadow: 0px 4px 8px rgba(61, 61, 61, 0.32)
			

	&__content
		position: relative

	&__hidden
		user-select: none
	
	&__fade
		cursor: pointer
		position: absolute
		top: 0
		bottom: 0
		left: 0
		right: 0
		background: linear-gradient(360deg, #F5F5F5 0%, rgba(245, 245, 245, 0.71) 100%)


.archive__btn
	$arrow-height: 14px
	$arrow-side-height: 9px
	$line-width: 2px
	.archive__cross-btn
		height: $arrow-height
		width: $line-width
		background-color: $black
		position: relative
		border-radius: 1px 
		$bias: 0px
		transition: transform .2s ease
		
		&::after, &::before
			content: ''	
			height: $arrow-side-height
			width: $line-width
			position: absolute
			background-color: $black
			border-radius: 1px
			transition: transform .2s ease

		&::after
			bottom: 0
			right: $bias
			transform: rotate(45deg)
			transform-origin: bottom center

		&::before
			bottom: 0
			left: $bias
			transform: rotate(-45deg)
			transform-origin: bottom center

	&--active
		.archive__cross-btn
			$arrow-height: $arrow-height + 5
			$line-width: 2px

			width: $line-width
			background-color: $white
			transform: rotate(45deg)
			height: $arrow-height
			
			&::after, &::before
				height: $arrow-height / 2
				width: $line-width
				background-color: $white

			&::before
				transform: translateY(-$arrow-height / 2) rotate(270deg)

			&::after
				transform: translateY(-$arrow-height / 2) rotate(90deg)
		

</style>