<template>
	<qr
		:viewbox="viewbox"
		v-if="type == 'qr'"
	/>
	<changes
		:viewbox="viewbox"
		v-else-if="type == 'changes'"
	/>
</template>

<script>
import qr from '@/components/basic/qr-dropdown'
import changes from '@/components/basic/changes'

export default {
	name: "dropdown-provider",
	components: {
		qr,
		changes
	},
	props: {
		viewbox: {
			type: Object
		}
	},
	computed: {
		type(){
			return this.$store.getters['dropdown/type']
		}
	}
}
</script>

<style>

</style>