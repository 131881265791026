<template>
	<b-absolute
		v-bind="pos"
	>
		<div class="changes" ref="dropdown">
			<div class="changes__header">
				<div class="changes__title">
					Список изменений
				</div>
				<div class="changes__close" @click="$store.commit('dropdown/setVisible', false)">
					<svg-icon icon-class="cross"></svg-icon>
				</div>
			</div>

			<div class="changes__body">
				<div class="changes__wrapper">
					<template
						v-if="changes.length > 0"
					>
						<div
							v-for="change in changes" :key="change.key"
							class="change"
						>
							<span class="change__username">
								[{{change.username}}]:
							</span>
							<span class="change__message">
								{{change.comment}}
							</span>
						</div>
					</template>
					<div v-else class="change change--empty">пусто</div>
				</div>
			</div>
		</div>
	</b-absolute>
</template>

<script>
import absolute from './absolute'
import { nextTick } from 'vue'

export default {
	name: 'changes-dropdown',
  components: {
		'b-absolute': absolute
	},
	props: {
		viewbox: {
			type: Object
		}
	},
	data: () => ({
		self: null,
		pos: {},
		verticalOffset: 30
	}),
	computed: {
		file(){
			return this.$store.getters['dropdown/data']
		},
		changes(){
			return this.file.changes
		},
		ddOptions(){
			return this.$store.getters['dropdown/options']
		},
		ddOptionsString(){
			return JSON.stringify(this.ddOptions)
		}
	},
	methods:{
		overflow(){
			if(this.self){
				if(!this.viewbox || !this.self){
					return false
				}
				const viewboxPos = this.viewbox.getBoundingClientRect();
				const selfPos = this.self.getBoundingClientRect();

				const fullHeight = selfPos.height + this.verticalOffset;
				const upperDot = this.ddOptions.y - fullHeight;

				const viewboxTop = viewboxPos.top + this.viewbox.scrollTop;

				return viewboxTop > upperDot
			}
			return false
		},
		position(){
			const data = {};

			if(this.$windowWidth >= 768){

				if(!this.overflow()){
					data.top = true
					data.offsetY = -this.verticalOffset
				} else {
					data.bottom = true
					data.offsetY = this.verticalOffset
				}

			} else {
				data.left = true
				data.offsetX = -this.verticalOffset
			}
			this.pos = data;
		},
		
	},
	mounted(){
		this.self = this.$refs.dropdown;
	},
	watch: {
		ddOptionsString(){
			nextTick()
			.then(() => {
				this.self = this.$refs.dropdown;
				this.position();
			})
		}
	}
}
</script>

<style scoped lang="sass">

.changes
	background-color: $white
	border-radius: 18px

	&::after
		height: 30px
		width: 30px
		background-color: $white
		
		position: absolute
		border-radius: 7px
		border: 1px solid rgba(0, 0, 0, 0.06)
		clip-path: polygon(0% 0%, 0% 100%, 100% 100%)

	&__header
		display: flex
		justify-content: space-between
		padding: 20px 27px
		border-bottom: 1px solid $deviders

	&__body
		min-width: 240px
		max-width: 320px
		position: relative
		padding: 10px 20px
		z-index: 5

		*
			flex-shrink: 0

	&__wrapper
		overflow-y: auto
		max-height: 360px

	&__close
		cursor: pointer

.change
	font-size: 14px

	&--empty
		display: flex
		align-items: center
		justify-content: center

	&:not(:last-of-type)
		margin-bottom: 10px

	&__username
		color: $gray-dark-3

	&__message
		word-break: break-word

.fade-enter-active, .fade-leave-active 
	transition: opacity .4s ease, padding .5s ease

.fade-enter, .fade-leave-to
	opacity: 0
	padding-top: 0px
	padding-bottom: 0px


</style>


<style lang="sass">

.dropdown
	&--top
		.changes::after
			content: ''
			top: initial
			right: initial
			bottom: 0
			left: 50%
			transform: translateY(calc(50% - 1px)) translateX(-50%) rotate(-45deg)
	&--bottom
		.changes::after
			content: ''
			top: 0
			right: initial
			bottom: initial
			left: 50%
			transform: translateY(calc(-50% + 1px)) translateX(-50%) rotate(135deg)
	&--left
		.changes::after
			content: ''
			top: 50%
			right: 0
			bottom: initial
			left: initial
			transform: translateY(-50%) translateX(calc(50% - 1px)) rotate(225deg)
	&--right
		.changes::after
			content: ''
			top: 50%
			right: initial
			bottom: initial
			left: 0
			transform: translateY(-50%) translateX(calc(-50% + 1px)) rotate(45deg)
	

</style>