<template>
	<div class="game" ref="container">
		<router-link class="go-back" :to="{name: 'Games hub'}">
			<div class="go-back__icon">
				&larr;
			</div>
			<div class="go-back__text">
				К списку проектов
			</div>
		</router-link>

		<header class="game__header">
			<b-game-icon class="game__image" :image-link="project.icon_link"/>
			<div class="game__info info">
				<div class="info__header">
					<div class="game__title">
						{{project.full_name || project.short_name}}
					</div>
					<div class="game__pin" @click="pinGame">
						<b-pin :pinned="project.pinned" rounded :rotate="!project.pinned" :gray="!project.pinned" :big="$windowWidth > 768"/>
					</div>
				</div>
				<div class="info__version">
					<span class="version-word">
						Версия:
					</span>
					{{version}}
				</div>
			</div>	
		</header>

		<b-project-info
			class="header__info"
			:notion-link="project.notion_link"
			:appstore-link="project.app_store_link"
			:googleplay-link="project.google_play_link"
			:site-link="project.site_link"
		/>

		<b-tooltip class="tooltip"/>

		<div class="platform" v-if="platform.os != 'any'">
			<div class="platform__title">
				{{platform.fullTitle}}
			</div>
			<div class="platform__formats formats" v-if="platform.title == 'Android'">
				<div class="formats__title">
					Форматы:
				</div>
				<div class="formats__container">
					<div
						v-for="format in formats" :key="format"
						class="formats__item"
						:class="{ 'formats__item--active': selectedFormat == format }"
						@click="() => selectedFormat = format"
					>
						{{format}}
					</div>
				</div>
			</div>
		</div>

		<b-branch-container :branches="splitArchived.active" :extension="selectedFormat" transition/>

		<div class="fw-divider"></div>

		<b-archive :branches="splitArchived.archived" :extension="selectedFormat" :parent="container" :project-id="project.id"/>	

		<div class="fw-divider xs"></div>

		<b-project-info
			class="footer__info"
			:notion-link="project.notion_link"
			:appstore-link="project.app_store_link"
			:googleplay-link="project.google_play_link"
			:site-link="project.site_link"
		/>

		<b-footer
			class="projects__footer xs"
			no-padding-left
		/>

		<b-dropdown
			:viewbox="$refs.container"
		></b-dropdown>
	</div>
</template>

<script>
import api from "@/api"
import pin from "./basic/icons/pin"
import gameIcon from "./basic/project_icon"
import tooltip from "./basic/tooltip"
import branchContainer from "./branch-container"
import archive from "./archive"
import footer from "@/components/footer"
import projectInfo from "@/components/project-info"
import dropdown from '@/components/basic/dropdown-abs'

export default {
	name: 'game',
	components: {
		"b-pin": pin,
		"b-game-icon": gameIcon,
		"b-tooltip": tooltip,
		"b-branch-container": branchContainer,
		"b-footer": footer,
		"b-project-info": projectInfo,
		"b-archive": archive,
		"b-dropdown": dropdown
	},
	data: () => ({
		formats: [
			'Все',
			'.apk',
			'.aab'
		],
		selectedFormat: 'Все',
		container: null
	}),
	computed: {
		project(){
			const all = this.$store.getters['projects/all']
			if(this.$route?.params?.gameId){
				const found = all.find(proj => proj.id == this.$route.params.gameId)
				if(!found){
					this.$router.replace({ name: '404' })
					return all[0]
				}
				return found
			} else {
				return all[0]
			}
		},
		splitArchived(){
			const result = {archived: [], active: []};
			for (const branch of this.project.branches) {
				if(branch.archived)
					result.archived.push(branch)
				else
					result.active.push(branch)
			}
			return result
		},
		version(){
			if(this?.project?.latest_version?.version){
				const version = this?.project?.latest_version?.version
				
				const date = new Date(this?.project?.latest_version?.updated)
				let ye = date.getFullYear();
				ye = ye % 100;

				const strdate = [date.getDate(), date.getMonth() + 1, ye]
				
				return `${version} (${strdate.map(el => String(el).padStart(2, "0")).join('.')})`
			} else {
				return "Нет данных"
			}

		},
		platform(){
			return this.$store.getters['platforms/selected']
		},
		archive(){
			const data = this.$store.getters['projects/archived']?.[this.$route.params.gameId]
			return data
		},
	},
	methods: {
		pinGame(){
			api.manageProject(this.project.id, !this.project.pinned)
			.then( res => {
				if(res == 'OK'){
					this.$store.dispatch('projects/fetch')
				}
			})
		}
	},
	created(){
		this.$store.dispatch('projects/fetchArchived', {project_id: this.project.id})
	},
	mounted(){
		this.container = this.$refs.container
	},
	watch:{
		$windowWidth(){
			this.$store.dispatch('dropdown/recalculate');
		}
	}
}
</script>

<style scoped lang="sass">

.platform
	margin-bottom: 20px
	display: flex
	align-items: flex-end
	grid-gap: 34px

	&__title
		font-weight: 600
		font-size: 28px
		color: $black
		display: none

.formats
	display: flex
	align-items: center
	grid-gap: 7px

	position: relative
	top: 4px

	&__title
		font-size: 16px
		color: $secondary-dark

	&__container
		display: flex
		grid-gap: 6px

	&__item
		padding: 7px 9px
		border-radius: 9px
		cursor: pointer
		transition: colro .2s ease, box-shadow .2s ease

		&--active
			color: $accent-buttons
			box-shadow: inset 0 0 0 1px $accent-buttons

		&:hover
			color: $accent-buttons


.fw-divider
	height: 1px
	background-color: rgba($black, 0.06)
	margin-top: 40px
	margin-bottom: 27px

.game
	padding: 12px 24px 24px
	position: relative
	
	&__header
		border-bottom: 1px solid $gray-light-2
		padding-bottom: 17px
		margin-top: 5px
		margin-bottom: 12px
		display: flex
		grid-gap: 16px

	&__image
		flex-shrink: 0
		$size: 72px
		height: $size
		width: $size
		border-radius: $size * 0.26311

	&__info
		display: flex
		flex-direction: column
		justify-content: space-between
		padding: 4px 0
		min-width: 0

	&__title
		display: -webkit-box
		text-decoration: underline
		-webkit-line-clamp: 2
		-webkit-box-orient: vertical
		overflow: hidden
		text-overflow: ellipsis
		flex-grow: 1

.info
	&__version
		font-size: 14px
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis
		text-decoration: none

	&__header
		display: flex
		grid-gap: 16px

.version-word
	color: $third-dark

.go-back
	text-decoration: none
	display: inline-flex
	grid-gap: 12px
	padding: 12px 0 12px 0

	&__icon
		font-size: 20px
		color: $third-dark

	&__text
		font-size: 16px
		color: $main-dark


.tooltip
	margin-bottom: 28px

.header__info
	display: none
	border-bottom: 1px solid $gray-light-2
	margin-bottom: 20px
	padding-bottom: 20px

@media screen and (min-width: $sm)
	.platform
		flex-direction: column
		align-items: start
		grid-gap: 10px
		&__title
			display: block

	.info
		justify-content: center

		&__header
			align-items: center

		&__version
			display: none

	.game
		&__title
			font-size: 28px
			font-weight: 600
			text-decoration: none

		&__header
			border: none
			padding-bottom: 0

	.header__info
		display: block

	.footer__info
		display: none

	.go-back
		display: none

	.xs
		display: none
	
	
@media screen and (min-width: $md)
	.platform
		flex-direction: row
		align-items: flex-end
		grid-gap: 34px
		&__title
			display: block


</style>