<template>
	<b-absolute
		:offsetX="-168"
	>
		<div
			class="dropdown-qr"
		>
			<div class="dropdown-qr__header">
				<div class="dropdown-qr__title">
					QR для загрузки
				</div>
				<div class="dropdown-qr__close" @click="$store.commit('dropdown/setVisible', false)">
					<svg-icon icon-class="cross"></svg-icon>
				</div>
			</div>
			<div class="dropdown-qr__body">
				<div class="dropdown-qr__image">
					<transition name="fade" mode="out-in">
						<div v-if="!link" class="placeholder">
							<svg-icon class="placeholder-item" icon-class="placeholder"></svg-icon>
						</div>
					</transition>
					<qrcode-vue v-show="link" :value="link" :size="200" level="L"></qrcode-vue>
				</div>
			</div>
			<div v-if="file.platform" class="dropdown-qr__footer-elem dropdown-qr__platform">
				<div class="platform__icon">
					<svg-icon :icon-class="`platform-${platformInfo.icon}-gray`"></svg-icon>
					{{platformInfo?.title ?? ''}}
				</div>
			</div>
			<div v-if="file.version" class="dropdown-qr__footer-elem dropdown-qr__version">
				<span class='version'>Версия:</span>
				{{file.version}}
			</div>
		</div>
	</b-absolute>
</template>

<script>
import absolute from './absolute'
import QrcodeVue from 'qrcode.vue'

export default {
	name: 'qr-dropdown-abs',
  components: {
		'b-absolute': absolute,
		QrcodeVue
	},
	props: {
	},
	computed: {
		platforms(){
			return this.$store.getters['platforms/list']
		},
		platformInfo(){
			return this.platforms.find(el => el.os == this.file.platform)
		},
		file(){
			return this.$store.getters['dropdown/data']
		},
		link(){
			return this.$store.getters['projects/urls'][this.file.id] ?? ''
		}
	}
}
</script>

<style scoped lang="sass">

.placeholder
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	display: flex
	align-items: center
	justify-content: center
	background-color: $white

	&-item
		font-size: 120px

.dropdown-qr
	background-color: $white
	border-radius: 18px

	&::after
		content: ''
		height: 30px
		width: 30px
		background-color: $white
		
		position: absolute
		top: 50%
		right: 0
		border-radius: 7px
		border: 1px solid rgba(0, 0, 0, 0.06)
		clip-path: polygon(0% 0%, 0% 100%, 100% 100%)
		transform: translateY(-50%) translateX(calc(50% - 1px)) rotate(225deg)

	&__header
		display: flex
		justify-content: space-between
		padding: 20px 27px
		border-bottom: 1px solid $deviders

	&__body
		display: flex
		align-items: center
		justify-content: center
		position: relative
		padding: 21px 40px 40px 40px
		z-index: 5

		*
			flex-shrink: 0
		
	&__image
		position: relative
		display: flex
		justify-content: center
		align-items: center
		$size: 200px
		width: $size
		height: $size


	&__footer-elem
		position: absolute
		bottom: 16px

	&__version
		right: 27px
		font-size: 10px

	&__platform
		left: 37px
		font-size: 10px

	&__close
		cursor: pointer

.version
	color: #9F9F9F

.fade-enter-active, .fade-leave-active 
	transition: opacity .4s ease, padding .5s ease

.fade-enter, .fade-leave-to
	opacity: 0
	padding-top: 0px
	padding-bottom: 0px

	
</style>