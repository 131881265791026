<template>
	<footer class="footer" :class="{'login-page': loginPage, 'aside': aside, 'no-padding-left': noPaddingLeft}">
		<div class="footer__feedback" @click="() => $store.commit('setModal', true)">
			Обратная связь
		</div>
		<div v-if="!noTutorial" @click="() => $router.push({name: 'Tutorial'})" class="footer__tutorial">
			Повторить обучение
		</div>
		<div class="footer__copyright">
			<div class="copyright__icon">
				<img src="/img/copyright.svg" alt="copyright">
			</div>
			Beresnev Games, 2017-{{currentYear}}
		</div>
	</footer>
</template>

<script>
export default {
	name: "b-footer",
	props: {
		'login-page': {
			type: Boolean,
		},
		'aside': {
			type: Boolean,
		},
		'no-padding-left': {
			type: Boolean,
		},
		'no-tutorial': {
			type: Boolean,
		}
	},
	computed: {
		currentYear(){
			return (new Date()).getFullYear();
		}
	}
}
</script>

<style scoped lang="sass">
.footer
	padding: 24px
	color: $secondary-dark
	font-size: 13px
	display: flex
	flex-direction: column
	grid-gap: 12px
	width: 100%

	&.aside
		padding: 24px 0
		grid-gap: 20px

		.footer__tutorial
			padding: 0 15px		
	
	&__feedback, &__tutorial
		text-decoration: underline
		cursor: pointer

	&__feedback
		display: none

	&.no-padding-left
		padding-left: 0

.login-page
	margin: 0 auto
	.footer__tutorial
		display: none

	.footer__feedback
		display: block

.copyright__icon
	display: inline-block

	
@media screen and (min-width: $xs)
	.footer:not(.aside)
		width: 366px
		padding-right: 0
		padding-left: 0
		flex-direction: row
		justify-content: space-between

	.footer__tutorial
		order: 3

	.footer__feedback
		order: 2

	.footer__copyright
		order: 1

	.aside
		.footer__copyright
			order: 4

@media screen and (min-width: $md)
	
	.login-page
		&.footer
			width: 533px

		.footer__tutorial
			display: block

	
</style>