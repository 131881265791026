<template>
	<div
		class="pin__wrapper"
		:class="{
			'parent__hover': parentHover,
			'pin__wrapper--rounded': rounded,
			'pin__wrapper--rotate': rotate,
			'pin__wrapper--hidden': hidden,
			'pin__wrapper--big': big,
		}"
		@mouseover="() => hover = true"
		@mouseleave="() => hover = false"
	>
		<svg class="pin__item" width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" :style="styles">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.65723 12.9089L14.4334 12.9089L11.5453 21.8305L8.65723 12.9089Z"
				fill-opacity="0.32"
				:fill="fillColor"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M14.8182 4.18182L15.9091 4.18182L15.9091 2L7.18182 2L7.18182 4.18182L8.27273 4.18182L8.27273 8.54546L5.00006 11.8181L5 11.8181V11.8182L5 12.909L18.0909 12.909L18.0909 11.8182L18.0909 11.8181L18.0909 11.8181L14.8182 8.54546L14.8182 4.18182Z"
				:fill="fillColor"
			/>
		</svg>

	</div>
</template>

<script>
export default {
	name: 'pin-icon',
	data: () => ({
		hover: false
	}),
	props: {
		pinned: {
			type: Boolean,
			default: false
		},
		hidden: {
			type: Boolean,
			default: false
		},
		rounded: {
			type: Boolean,
			default: false
		},
		'parent-hover': {
			type: Boolean,
			default: false
		},
		gray: {
			type: Boolean,
			default: false
		},
		color: {
			type: [String, undefined],
			default: null
		},
		rotate: {
			type: Boolean,
			default: false
		},
		light: {
			type: Boolean,
			default: false
		},
		'rotate-degs': {
			type: Number,
			default: 45
		},
		big: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		fillColor(){
			if(this.color) return this.color
			if(this.gray) return '#B7B7B7'
			return '#700BFA'
		},
		styles(){
			const style = {}
			if(this.rotate)
				style.transform = `rotate(${this.rotateDegs}deg)`
			return style
		}
	}
}
</script>

<style scoped lang='sass'>
.pin
	&__item
		width: 23px
		height: 23px

	&__wrapper
		cursor: pointer
		width: 31px
		height: 31px
		padding: 3px
		border-radius: 11px
		transition: background-color .2s ease
		display: flex
		align-items: center
		justify-content: center
		
		&--rounded
			border: 1px solid rgba(0, 0, 0, 0.12)
			border-radius: 50%
		
		&--hidden
			opacity: 0
			transition: opacity .1s ease
			&.parent__hover
				opacity: .5

		&--big
			width: 40px
			height: 40px
			padding: 8px

		&.parrent__hover
			opacity: .6

		&:hover
			background-color: $dropdown-hower
			.pin__item
				path
					fill: #700BFA

</style>

<style lang="sass">
.active
	.pin
		&__item
			path
				fill: $white
		&__wrapper
			&:hover
				background-color: #ffffff40

// .project:hover
// 	.pin__wrapper
// 		opacity: .6
</style>