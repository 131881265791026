<template>
	<aside class="projects">
		<header class="projects__header">
			<div class="projects__title">
				Мои проекты
			</div>
			<div class="projects__quantity">
				{{projects.length > 0 ? projects.length : ""}}
			</div>
		</header>
		<main class="projects__main">
			<div class="projects__container">
				<b-project
					v-if="projects.length == 0"
					blank
				/>
				<transition-group name="projects" v-else>
					<b-project
						v-for="(project, index) in projects" :key="project.id"
						class="projects__card"
						:class="{ 'active': index == 0 && higthlightFirst }"
						:project="project"
					/>
				</transition-group>
			</div>
			<div class="projects__info info">
				Что-бы добавить новый продукт, обратитесь 
к своему менеджеру. Если возникли проблемы 
с добавление, воспользуйтесь формой 
<span class="info__link" @click="() => $store.commit('setModal', true)">обратной&nbsp;связи</span>
			</div>
			<b-invite
				v-if="isEmpty"
				class="projects__invite"
			/>
		</main>
		<b-footer
			class="projects__footer"
			aside
		/>
	</aside>	
</template>

<script>
import project from "@/components/project"
import footer from "@/components/footer"
import invite from "@/components/invite"

export default {
	name: "my-projects",
	components: {
		"b-project": project,
		"b-footer": footer,
		"b-invite": invite
	},
	computed: {
		projects(){
			return this.$store.getters['projects/sorted']
		},
		higthlightFirst(){
			if(!this.$store.getters['auth/isLoggedIn'])
				return false
				
			if(this.$windowWidth < 768)
				return false

			if(this.$route.params.gameId || this.$windowWidth < 768)
				return false

			return true
		},
		isEmpty(){
			return !this.projects.length
		}
	}
}
</script>

<style scoped lang="sass">

.projects
	width: 100%
	background: $aside-background
	flex: 0 0 auto
	padding: 17px 24px 0
	display: flex
	flex-direction: column
	min-height: 0px

	&__header
		display: flex
		justify-content: space-between
		align-items: center
		margin-bottom: 9px

	&__title
		font-weight: 600
		font-size: 28px
		line-height: 36px

	&__quantity
		font-size: 28px
		line-height: 36px
		color: $third-dark

	&__main
		flex: 1 0

	&__container
		margin-bottom: 28px

	&__info
		font-size: 12px
		line-height: 18px
		color: $secondary-dark
		padding: 0 15px

	&__invite
		margin-top: 40px

	&__footer
		margin-top: 28px

		&.only-whide
			display: none

	&__card
		margin-bottom: 10px

.project__link
	display: block
	margin-bottom: 10px
	text-decoration: initial 
	color: initial

.info__link
	color: $main-dark
	text-decoration: underline
	cursor: pointer
	transition: color .2s ease
	&:hover
		color: $links-color

@media screen and (min-width: $sm)
	.projects
		border-right: 1px solid $deviders

		&__invite
			display: none

		&__footer
			margin-top: 28px

			&.only-whide
				display: flex

@media screen and (min-width: $md)
	.projects
		&__main
			flex: 0 0
		

// .projects-enter-active, .projects-leave-active 
// 	transition: all 1s

// .projects-enter, .projects-leave-to
// 	opacity: 0
	// transform: scale(.2)

.projects-move
	transition: transform .5s ease


</style>