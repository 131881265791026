<template>
	<div
		class="file"
		:class="{
			'file--pinned': pinned,
			'file--archived': archived,
		}"
	>
		<div class="file__item" @click="downloadFile(current !== 'Apple iOS')">
			<div class="file__logo">
				<svg-icon :icon-class="icon"></svg-icon>
			</div>
			<div class="file__info">
				<div class="file__version">{{file.version}}</div>
				<div class="file__date">{{date}}</div>
			</div>
			<!-- v-if="file.platform == 'android' || current == 'Apple iOS'" -->
			<div v-if="current !== 'Apple iOS'" class="file__extension-wrapper">
				<div class="file__extension">
					{{file.extension}}
				</div>
			</div>
		</div>
		<div class="file__buttons">

			<!-- <div
				v-if="file.platform == 'ios' && current != 'Android'"
				class="file__button--ipa"
				@click="downloadFile(true)"
			>
				<svg-icon icon-class="arrow-white"/>
				.ipa
			</div> -->

			<div
				class="file__buttons-group"
				@click="() => getLink(false, false)"
			>
				<div
					class="file__button"
					@click="(e) => openDropdown(e, 'changes')"
				>
					<svg-icon icon-class="list"></svg-icon>
				</div>
				<div
					v-if="$windowWidth >= 768"
					class="file__button file__button--qr"
					@click="(e) => openDropdown(e, 'qr')"
				>
					<svg-icon icon-class="qr"></svg-icon>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from "@/api"

export default {
	name: "b-file",
	props: {
		file: {
			type: Object,
			required: true
		},
		pinned: {
			type: Boolean,
			default: false
		},
		archived: {
			type: Boolean,
			default: false
		},
	},
	computed: {
		date(){
			const date = new Date(this.file.updated)
			let ye = date.getFullYear();
			ye = ye % 100;

			const strdate = [date.getDate(), date.getMonth() + 1, ye]

			let delta = new Date().valueOf() - date.valueOf()
			delta = delta / 1000
			let deltaString = '';
			const hours = delta / 3600;
			
			if(hours >= 24){
				deltaString = `${(hours/24).toFixed(0)}\u00A0д.`
			} else {
				const mins = (delta % 3600) / 60;
				if(hours < 1){
					deltaString = `${mins.toFixed(0)}\u00A0мин.`
				} else {
					deltaString = `${hours.toFixed(0)}\u00A0ч.`
				}
			}
			
			return `${strdate.map(el => String(el).padStart(2, "0")).join('.')}  |  ${deltaString}`
		},
		icon(){
			let mode = '-white';
			if(this.archived)
				mode="-black"
			switch (this.file.platform) {
				case 'android':
					return 'platform-android' + mode
				case 'ios':
					return 'platform-apple' + mode
				default:
					return 'placeholder' + mode
			}
		},
		link(){
			return this.$store.getters['projects/urls'][this.file.id] ?? ''
		},
		current(){
			return this.$store.getters['platforms/current']
		}
	},
	methods: {
		getLink(openAfterFetch, ipa = false){
			if(!this.link){
				api.download(this.file.id, ipa)
				.then(res => {
					this.$store.commit('projects/setLink', {id: this.file.id, url: res.url})
					if(openAfterFetch){
						window.open(res.url)
					}
				})
			} else {
				if(openAfterFetch){
					window.open(this.link)
				}
			}
		},
		downloadFile(ipa){
			this.getLink(true, ipa)
		},
		openDropdown(e, type){
			this.$store.dispatch('dropdown/open', {
				target: e.target,
				data: {
					type,
					file: this.file
				}
			})
		}
	}
}
</script>

<style scoped lang="sass">

.qr__toggle::after
	content: ""
	position: absolute
	bottom: 0
	top: 0
	right: 0
	left: 0
	
.file
	display: flex
	grid-gap: 10px

	&:not(:last-of-type)
		margin-bottom: 12px
	&__item
		display: flex
		flex-grow: 1
		background: $buttons
		border-radius: 12px
		color: $white
		// padding: 5px
		cursor: pointer
		transition: box-shadow .2s ease, background-color .2s ease

		&:hover
			box-shadow: 0px 4px 8px rgba($buttons, 0.32)
			background: lighten($buttons, 13%) 

	&__logo
		padding: 13px
		font-size: 20px

		svg
			fill: $white
		
	&__info
		display: flex
		flex-direction: column
		justify-content: center
		flex-grow: 1

	&__version
		font-size: 16px

	&__date
		font-size: 10px
		line-height: 18px
		color: $third-dark
		margin-right: 16px
		white-space: pre-wrap

	&__extension
		border: 1px solid $white
		border-radius: 8px
		box-sizing: border-box
		padding: 4px
		&-wrapper
			display: flex
			align-items: center
			justify-content: center
			margin-right: 12px

	&__buttons
		display: flex
		grid-gap: 10px

		&-group
			display: flex



	&__button
		$bdr: 12px
		$bd: 1px solid $buttons-border
		cursor: pointer
		display: flex
		justify-content: center
		align-items: center
		border: $bd
		padding: 12px
		transition: background-color .2s ease
		user-select: none

		&:first-of-type
			border-top-left-radius: 12px
			border-bottom-left-radius: 12px

		&:last-of-type
			border-top-right-radius: 12px
			border-bottom-right-radius: 12px

		&:not(:last-of-type)
			border-right: none

		&:hover
			background-color: rgba(#bdbdbd, 0.32)

		&--ipa
			display: flex
			justify-content: center
			align-items: center
			grid-gap: 6px
			background-color: $black
			color: $white
			padding: 0 13px
			border-radius: 12px
			cursor: pointer

			svg
				font-size: 12px
				position: relative
				top: 2px

	&--pinned
		.file__item
			background-color: $links-color

			&:hover
				box-shadow: 0px 4px 8px rgba($links-color, 0.32)
				background: darken($links-color, 13%)

		.file__date
			color: $accent-light

	&--archived
		.file__logo
			svg
				fill: $black	
		.file__item
			color: $main-dark
			background-color: $gray-dark-1

			&:hover
				box-shadow: 0px 4px 8px rgba($gray-dark-1, 0.32)
				background: darken($gray-dark-1, 13%)

		.file__date
			color: rgba(0, 0, 0, 0.64)

		.file__extension
			border: 1px solid $main-dark


@media screen and (min-width: $sm)
	.file
		&__info
			flex-direction: row
			align-items: center
			justify-content: space-between
			grid-gap: 10px

		&__version
			font-size: 18px

		&__date
			font-size: 12px
			text-align: right


</style>