<template>
	<div class="project__icon" :class="{'have-image': imageLink}">
		<img :src="imageLink ? imageLink : '/img/placeholder.svg'" alt="placeholder">
	</div>
</template>

<script>
export default {
	name: 'project-icon',
	props: {
		'image-link': {
			type: [String, undefined],
			required: true
		}
	}
}
</script>

<style scoped lang="sass">
.project__icon
	box-shadow: inset 0 0 0 1.3px rgba(0,0,0, .06)
	overflow: hidden
	display: flex
	justify-content: center
	align-items: center
	background-color: $secondary-background
	img
		display: block
		
	&.have-image
		img
			object-fit: contain
			height: 100%
			width: 100%
			box-shadow: inset 0 0 0 1.3px rgba(0,0,0, .06)
</style>