<template>
	<div class="branch__container">
		<transition-group v-if="transition" name="branches">
			<b-branch
				v-for="branch in branchesSorted" :key="branch.id"
				:branch="branch"
				:count="count"
				:no-fade="noFade"
				:extension="extension"
			/>
		</transition-group>
		<template
			v-else
		>
			<b-branch
				v-for="branch in branchesSorted" :key="branch.id"
				:branch="branch"
				:count="count"
				:no-fade="noFade"
				:extension="extension"
				:project-id="projectId"
			/>
		</template>
	</div>
</template>

<script>
import branch from "./branch.vue"

export default {
	name: "b-branch-wrapper",
	components: {
		'b-branch': branch
	},
	props: {
		branches: {
			type: Object,
			required: true
		},
		count: {
			type: Number,
			default: 3
		},
		'no-fade': {
			type: Boolean,
			default: false
		},
		extension: {
			type: String,
			default: 'Все'
		},
		transition: {
			type: Boolean
		},
		"project-id": {
			type: Number,
			default: 0
		}
	},
	computed: {
		branchesSorted(){
			const branches = this.branches
			return branches.sort((a, b) => b.pinned - a.pinned)
		}
	}
}
</script>

<style scoped lang="sass">
.branch__container
	position: relative
	display: grid
	grid-gap: 32px 72px
	grid-template-columns: repeat( auto-fit, minmax(360px, 1fr) )

.branches-move
	transition: transform .5s ease

@media screen and (max-width: $xs)
	.branch__container
		grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) )

</style>