<template>
	<div class="branch" :class="{ 'branch--empty': allFiles.length == 0 }">
		<div class="branch__header">
			<div class="branch__title">
				{{branch.full_name}}
			</div>
			<div class="branch__pin" @click="tooglePin">
				<b-heart
					:filled="branch.pinned"
					:unactive="!branch.pinned"
				/>
			</div>
		</div>
		<div class="branch__content" ref="container">
			<div v-if="fileList.length == 0" class="branch__empty">
				пусто
			</div>
			<b-file
				v-for="file in fileList" :key="file.key"
				:file="file"
				:pinned="branch.pinned"
				:archived="branch.archived"
			/>
			<div class="toggler" v-if="full" @click="() => this.full = fals">
				<span class="fade__text">
					Свернуть
				</span>
				<svg-icon class="fade__icon" icon-class="arrow" style="transform: rotate(180deg)"/>
			</div>

			<div v-if="showFadeElem && fileList.length > 0" class="branch__hidden toggler" @click="() => this.full = true">
				<div class="branch__fade fade">
					<span class="fade__text">
						Показать все
					</span>
					<svg-icon class="fade__icon" icon-class="arrow"/>
				</div>
				<b-file
					:file="osFiles[count]"
					:pinned="branch.pinned"
					:archived="branch.archived"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import file from "./file.vue"
import heart from "./basic/icons/heart.vue"

import api from "@/api.js"

// import smoothReflow from 'vue-smooth-reflow'

export default {
	name: "b-branch",
	// mixins: [smoothReflow],
	components: {
		"b-file": file,
		"b-heart": heart 
	},
	props: {
		branch: {
			type: Object,
			required: true
		},
		count: {
			type: Number,
			default: 3
		},
		'no-fade': {
			type: Boolean,
			default: false
		},
		extension: {
			type: String,
			default: 'Все'
		},
		"project-id": {
			type: Number,
			default: 0
		}
	},
	data: () => ({
		full: false
	}),
	computed: {
		osFiles(){
			let files = this.branch.files;
			files = files.filter(file => file.platform == this.platform.os || this.platform.os == 'any')
			return files
		},
		extensionFiles(){
			return this.osFiles.filter(file => file.extension == this.extension || this.extension == 'Все');
		},
		allFiles(){
			let files;
			if(this.platform.os == 'android'){
				files = this.extensionFiles;
				} else {
				files = this.osFiles;
			}
			return files;
		},
		fileList(){
			let files = this.allFiles;

			if(this.full){
				return files
			}
			return files.slice(0, this.count)
		},
		showFadeElem(){
			return this.osFiles.length > this.count && !this.full && !this.noFade
		},
		platform(){
			return this.$store.getters['platforms/selected']
		}
	},
	methods: {
		tooglePin(){
			api.manageBranch(this.branch.id, !this.branch.pinned)
			.then( () => {
				this.$store.dispatch('projects/fetch')
				if(this.projectId){
					this.$store.dispatch('projects/fetchArchived', { project_id: this.projectId, unlimited: true })
				}
			})
		}
	},
	mounted(){
	},
}
</script>

<style scoped lang="sass">
.branch
	max-width: 404px
	&__header
		display: flex
		align-items: center
		grid-gap: 14px
		margin-bottom: 14px

	&__title
		font-weight: 600
		font-size: 18px
		line-height: 24px
		min-width: 0
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis

	&__pin
		display: flex
		justify-content: center
		align-items: center
		cursor: pointer

	&__hidden
		position: relative

	&__fade
		position: absolute
		top: 0
		bottom: 0
		right: 0
		left: 0

		background: linear-gradient(360deg, #F5F5F5 0%, rgba(245, 245, 245, 0.88) 100%)
		z-index: 1

		display: flex
		align-items: center
		padding-left: 16px
	
	&--empty
		display: none

.fade
	&__text
		text-decoration: underline
		margin-right: 12px

	&__icon
		font-size: 13px
		transform: translateY(1px)

.toggler
	cursor: pointer

</style>