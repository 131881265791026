<template>
	<div class="invite" :class="{'full-screen': fullScreen}">
		<div class="invite__text">
			Скоро тут будут игры, над которыми ты
будешь работать. Хорошего дня!
		</div>
		<div class="invite__icon">
			<img src="/img/b-heart.svg" alt="heart">
		</div>
	</div>
</template>

<script>
export default {
	name: 'invite',
	props: {
		'full-screen': {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style scoped lang='sass'>
.invite
	display: flex
	flex-direction: column
	align-items: center
	grid-gap: 11px

	&.full-screen
		height: 100%
		justify-content: center
		background-image: url('/img/placeholder-bg.png')
		background: linear-gradient(to bottom, transparent, $main-background), url("/img/placeholder-bg.svg")
		background-position: left top
		background-repeat: no-repeat
		background-size: 70%

	&__text
		font-weight: 600
		font-size: 28px
		line-height: 36px
		text-align: center
		max-width: 550px
		color: $main-dark


@media screen and (min-width: $md)
	.invite
		&.full-screen
			background: linear-gradient(to bottom, transparent, $main-background), url("/img/placeholder-bg-big.svg")
			background-position: left top
			background-repeat: no-repeat
			background-size: 80%

</style>